<template>
  <ul>
    <component
      :is="resolveNavItemComponent(link)"
      v-for="link in links"
      :key="link.header || link.title"
      :item="link"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      employeeLinks: ['home', 'dispatches', 'daas', 'riders'],
      partnerLinks: ['home', 'dispatches', 'daas', 'riders', 'payouts', 'accounts', 'settings', 'employees'],
      userType: JSON.parse(localStorage.getItem('user'))?.userType,
      links: [],
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  mounted() {
    this.links = this.userType === 'employee'
      ? this.items.filter(link => this.employeeLinks.includes(link.route))
      : this.items.filter(link => this.partnerLinks.includes(link.route))
  },
}
</script>
