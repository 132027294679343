export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Dispatches',
    route: 'dispatches',
    icon: 'TruckIcon',
  },
  {
    title: 'DAAS',
    route: 'daas',
    icon: 'BoxIcon',
  },
  {
    title: 'Riders',
    route: 'riders',
    icon: 'UsersIcon',
  },
  {
    title: 'Employees',
    route: 'employees',
    icon: 'UsersIcon',
  },
  {
    title: 'Payouts',
    route: 'payouts',
    icon: 'ActivityIcon',
  },
  {
    title: 'Accounts',
    route: 'accounts',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
