<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-rider
          @click="resetErrors"
        >
          <feather-icon
            icon="UsersIcon"
            size="16"
            class="bg-light-info"
          />
          <span class="align-middle ml-50">Add Rider</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isApproved"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-payout
          @click="resetErrors"
        >
          <feather-icon
            icon="ActivityIcon"
            size="16"
            class="bg-light-danger"
          />
          <span class="align-middle ml-50">Initiate Payout</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-account
          @click="resetErrors"
        >
          <feather-icon
            icon="CreditCardIcon"
            size="16"
            class="bg-light-success"
          />
          <span class="align-middle ml-50">Add Account</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon
            icon="MailIcon"
            size="16"
          />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item> -->
      </b-dropdown>
    </b-col>

    <!--  ADD RIDER MODAL -->
    <b-modal
      id="modal-rider"
      ref="modal-rider"
      centered
      title="Add a New Rider Details"
      ok-only
      ok-title="Add Rider"
      hide-footer
    >
      <div>
        <b-overlay
          :show="showRiderOverlay"
          rounded="sm"
          variant="dark"
        >
          <div>
            <!-- ERROR ALERT BOX -->
            <b-row v-if="hasError">
              <b-col cols="12 text-left">
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <h4 class="alert-heading">
                    {{ errors.message }}
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="hasError = !hasError"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="alert-body">
                    <div
                      v-for="(item, index) in errors.data"
                      :key="index"
                    >
                      * <small>{{ item.toString() }}</small>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-form
              @submit="onSubmitRider"
              @reset="onResetRider"
            >
              <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                  id="name"
                  v-model="rider.name"
                  type="text"
                  placeholder="Name"
                  required
                />
              </b-form-group>

              <b-form-group>
                <label for="email">Email:</label>
                <b-form-input
                  id="email"
                  v-model="rider.email"
                  type="email"
                  placeholder="Email Address"
                  required
                />
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group>
                    <label for="phone">Phone:</label>
                    <b-form-input
                      id="phone"
                      v-model="rider.phone"
                      type="text"
                      placeholder="Phone"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label for="vehicle">Vehicle:</label>
                    <b-form-input
                      id="vehicle"
                      v-model="rider.vehicle"
                      type="text"
                      placeholder="Vehicle"
                      list="vehicle-list"
                      required
                    />
                    <datalist id="vehicle-list">
                      <option>Bike</option>
                      <option>Car</option>
                    </datalist>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label for="lastName">Plate Number:</label>
                    <b-form-input
                      id="plate_number"
                      v-model="rider.plate_number"
                      type="text"
                      placeholder="Plate Number"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <label for="password">Create Password</label>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="password"
                        v-model="rider.password"
                        :type="password1FieldType"
                        placeholder="Create Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label for="password_confirmation">Confirm Password</label>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="password_confirmation"
                        v-model="rider.password_confirmation"
                        :type="password2FieldType"
                        placeholder="Confirm Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  md="12"
                  lg="12"
                >
                  <b-button
                    class="mx-1 float-right"
                    type="submit"
                    variant="primary"
                  >Submit</b-button>
                  <b-button
                    class="mx-0 float-right"
                    type="reset"
                    variant="danger"
                  >Reset</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </b-modal>

    <!--  INITIATE PAYOUT MODAL -->
    <b-modal
      v-if="isApproved"
      id="modal-payout"
      ref="modal-payout"
      centered
      title="Initiate Payout Details"
      hide-footer
    >
      <div>
        <b-overlay
          :show="showPayoutOverlay"
          rounded="sm"
          variant="dark"
        >
          <div>
            <!-- ERROR ALERT BOX -->
            <b-row v-if="hasError">
              <b-col cols="12 text-left">
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <h4 class="alert-heading">
                    {{ errors.message }}
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="hasError = !hasError"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="alert-body">
                    <div
                      v-for="(item, index) in errors.data"
                      :key="index"
                    >
                      * <small>{{ item.toString() }}</small>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-form
              @submit="onSubmitPayout"
              @reset="onResetPayout"
            >
              <b-form-group>
                <label for="amount">Amount:</label>
                <b-form-input
                  id="amount"
                  v-model="payout.amount"
                  type="text"
                  placeholder="Amount"
                  required
                />
              </b-form-group>
              <b-form-group>
                <label for="bank_code">Recipient Account:</label>
                <b-form-select
                  id="bank_name"
                  v-model="payout.recipient_code"
                  :options="accounts"
                  value-field="recipient_code"
                  text-field="account_text"
                  required
                />
              </b-form-group>
              <hr>
              <b-row>
                <b-col
                  md="12"
                  lg="12"
                >
                  <b-button
                    class="mx-1 float-right"
                    type="submit"
                    variant="primary"
                  >Submit</b-button>
                  <b-button
                    class="mx-0 float-right"
                    type="reset"
                    variant="danger"
                  >Reset</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </b-modal>

    <!--  ADD ACCOUNT MODAL -->
    <b-modal
      id="modal-account"
      ref="modal-account"
      centered
      title="Add Bank Account Details"
      hide-footer
    >
      <div>
        <b-overlay
          :show="showAccountOverlay"
          rounded="sm"
          variant="dark"
        >
          <div>
            <!-- ERROR ALERT BOX -->
            <b-row v-if="hasError">
              <b-col cols="12 text-left">
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <h4 class="alert-heading">
                    {{ errors.message }}
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    @click="hasError = !hasError"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="alert-body">
                    <div
                      v-for="(item, index) in errors.data"
                      :key="index"
                    >
                      * <small>{{ item.toString() }}</small>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-form
              @submit="onSubmitAccount"
              @reset="onResetAccount"
            >
              <b-form-group>
                <label for="account_name">Account Name:</label>
                <b-form-input
                  id="account_name"
                  v-model="account.account_name"
                  type="text"
                  placeholder="Account Name"
                  required
                />
              </b-form-group>

              <b-form-group>
                <label for="account_number">Account NUmber:</label>
                <b-form-input
                  id="account_number"
                  v-model="account.account_number"
                  type="text"
                  placeholder="Account Number"
                  required
                />
              </b-form-group>
              <b-form-group>
                <label for="bank_code">Bank Name:</label>
                <b-form-select
                  id="bank_name"
                  v-model="account.bank_code"
                  :options="banks"
                  value-field="code"
                  text-field="name"
                  required
                />
              </b-form-group>
              <hr>
              <b-row>
                <b-col
                  md="12"
                  lg="12"
                >
                  <b-button
                    class="mx-1 float-right"
                    type="submit"
                    variant="primary"
                  >Submit</b-button>
                  <b-button
                    class="mx-0 float-right"
                    type="reset"
                    variant="danger"
                  >Reset</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-overlay>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import store from '@/store/index'
import { apiUrl } from '@/constants/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Pusher from 'pusher-js'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      rider: {
        name: '',
        email: '',
        phone: '',
        vehicle: '',
        plate_number: '',
        password: '',
        password_confirmation: '',
      },
      payout: {
        amount: '',
        recipient_code: '',
      },
      account: {
        account_name: '',
        account_number: '',
        bank_name: '',
        bank_code: '',
      },
      accounts: [],
      banks: [
        /* { name: "Access", code: 1101 },
        { name: "Keystone", code: 1102 }, */
      ],
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      showRiderOverlay: false,
      showPayoutOverlay: false,
      showAccountOverlay: false,
      hasError: false,
      errors: {
        message: '',
        data: [],
      },
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isApproved() {
      return store.state.user.currentUser.user.kyc_approved == 1
    },
  },
  created() {
    this.subscribe()
    this.fetchAccounts()
    this.fetchBanks()
  },
  methods: {
    subscribe() {
      const pusher = new Pusher('30ce615d302d8db1a422', {
        cluster: 'mt1',
      })
      pusher.subscribe(
        `channel-partner-${store.state.user.currentUser.user.id}`,
      )
      pusher.bind('daas-event', data => {
        store.state.user.daasEvent = true
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title:
                `Info: Dispatch-as-a-service ${
                  data.daas.status == 'REQUEST' ? 'REQUEST' : 'PAYMENT'}`,
              icon: 'InfoIcon',
              text:
                `You have have received a Dispatch-as-a-service ${
                  data.daas.status == 'REQUEST' ? 'REQUEST' : 'PAYMENT'
                }.`,
              variant: 'info',
            },
          },
          {
            position: 'bottom-right',
            timeout: 86400 * 1000,
          },
        )
      })
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    onSubmitRider(event) {
      this.hasError = false
      this.showRiderOverlay = true
      event.preventDefault()

      // post data
      axios({
        url: `${apiUrl}rider`,
        data: this.rider,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          store.state.user.riderAdded = true // update riders table
          // TODO: Refresh rider metrics and table
          this.$refs['modal-rider'].hide()
          this.onResetRider(event)
          // console.log(JSON.stringify(response.data));
          this.$toast({
            component: ToastificationContent,
            props: {
              // title: "Success: Rider created successfully!",
              title: `Success: ${response.data.message}!`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.hasError = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${this.parseError(error.response)}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showRiderOverlay = false
        })
    },
    onResetRider(event) {
      event.preventDefault()
      // Reset our form values
      this.rider.name = ''
      this.rider.email = ''
      this.rider.phone = ''
      this.rider.vehicle = ''
      this.rider.plate_number = ''
      this.rider.password = ''
      this.rider.password_confirmation = ''
    },
    parseError(response) {
      if (response == undefined) return ''
      if (response.status == 422) {
        this.errors.message = response.data.message
        this.errors.data = response.data.errors

        return response.data.message
      } if (response.status == 500) {
        this.errors.message = response.data.message
        this.errors.data = []
        return response.status
      }
      return 'this is an error'
    },

    resetErrors() {
      this.hasError = false
      this.errors = {
        message: '',
        data: [],
      }
    },

    onSubmitPayout(event) {
      this.hasError = false
      this.showPayoutOverlay = true
      event.preventDefault()

      // post data
      axios({
        url: `${apiUrl}payout`,
        data: this.payout,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          // TODO: Refresh payout metrics and table
          this.$refs['modal-payout'].hide()
          // console.log(JSON.stringify(response.data));
          if (response.data.status && response.data.status == 'error') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error: ${response.data.message}`,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }

          this.onResetPayout(event)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}!`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          store.state.user.payoutAdded = true
        })
        .catch(error => {
          this.hasError = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${this.parseError(error.response)}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showPayoutOverlay = false
        })
    },
    onResetPayout(event) {
      event.preventDefault()
      this.payout.amount = ''
      this.payout.recipient_code = ''
    },

    onSubmitAccount(event) {
      this.hasError = false
      this.showAccountOverlay = true
      event.preventDefault()
      const code = this.account.bank_code
      this.account.bank_name = this.banks.filter(value => value.code == code)[0].name

      // post data
      axios({
        url: `${apiUrl}bankAccount`,
        data: this.account,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          store.state.user.accountAdded = true
          this.$refs['modal-account'].hide()
          this.onResetAccount(event)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}!`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          // TODO: Refresh account metrics and table
        })
        .catch(error => {
          this.hasError = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error: ${this.parseError(error.response)}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showAccountOverlay = false
        })
    },
    onResetAccount(event) {
      event.preventDefault()
      this.account.account_name = ''
      this.account.account_number = ''
      this.account.bank_name = ''
      this.account.bank_code = ''
    },

    fetchAccounts() {
      try {
        axios
          .get(`${apiUrl}bankAccount`, {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
          .then(response => {
            // console.log(response.data.data);
            const accounts = response.data.data
            this.accounts = accounts.map(account => ({
              recipient_code: account.recipient_code,
              account_text:
                `${account.account_name
                } [${
                  account.bank_name
                } - ${
                  account.account_number
                }]`,
            }))
            // console.log(this.accounts);
          })
          .catch(error => {
            console.log(error)
          })
      } catch (e) {
        console.log(e)
      }
    },

    fetchBanks() {
      try {
        axios
          .get(`${apiUrl}bankAccount/banks`, {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${store.state.user.currentUser.token}`,
            },
          })
          .then(response => {
            // console.log(response.data.data);
            this.banks = response.data.data
          })
          .catch(error => {
            console.log(error)
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
